import React, { FC } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
	margin: 0 auto;	
	max-width: 1000px;
  width: 100%;

	&.large {
		max-width: 1200px;
	}
`;

interface WrapperProps {
	children: any;
	className?: string;
}

const WrapperComponent: FC<WrapperProps> = (props) => {
  return (
		<Wrapper className={props.className}>
			{props.children}
		</Wrapper>
	)
};
  
export default WrapperComponent;
import React, { FC } from 'react';
import styled from "styled-components";

import enoch from '../../assets/images/clients/player-enoch-hero.jpeg';
import jalen from '../../assets/images/clients/player-jalen-hero.jpeg';
import jamal from '../../assets/images/clients/player-jamal-hero.jpeg';
import keeshawn from '../../assets/images/clients/player-keeshawn-hero.jpeg';
import kyler from '../../assets/images/clients/player-kyler-hero.jpeg';
import mouhamed from '../../assets/images/clients/player-mouhamed-hero.jpeg';
import nate from '../../assets/images/clients/player-nate-hero.jpeg';
import diongue from '../../assets/images/clients/player-diongue-hero.jpeg';
import jalenhill from '../../assets/images/clients/player-jalenhill-hero.jpeg';
import milosuzan from '../../assets/images/clients/player-milos-hero.jpeg';
import glenntaylorjr from '../../assets/images/clients/player-glen-hero.jpeg';

const Image = styled.div`
	bottom: 0.5px;
	left: 0;
	position: fixed;
	right: 0;
  top: 0.5px;
  z-index: 0;
	background-image: url(${props => props.src});
	background-size: cover;
	background-attachment: fixed;
	background-position: center;

	&.enochboakye {
		background-image: url(${enoch});
	}

	&.jalencook {
		background-image: url(${jalen});
	}

	&.jamalshead {
		background-image: url(${jamal});
	}

	&.keeshawnbarthelemy {
		background-image: url(${keeshawn});
	}

	&.kyleredwards {
		background-image: url(${kyler});
	}

	&.mouhamedgueye {
		background-image: url(${mouhamed});
	}

	&.naterobinson {
		background-image: url(${nate});
	}

	&.adramediongue {
		background-image: url(${diongue});
	}

	&.jalenhill {
		background-image: url(${jalenhill});
	}

	&.milosuzan {
		background-image: url(${milosuzan});
	}

	&.glenntaylorjr {
		background-image: url(${glenntaylorjr});
	}
`;

interface ImageProps {
	src?: string;
	className?: string;
}

const HeroImageComponent: FC<ImageProps> = (props) => {
  return (
	  <Image src={props.src} className={props.className} />
	)
};
  
export default HeroImageComponent;
import React, { FC } from 'react';
import styled from 'styled-components';

const Section = styled.section`
	background: #fff;
	padding: 3rem 0;
	position: relative;
	z-index: 2;
`;

interface SectionProps {
	background?: string;
	children: any;
}

const SectionComponent: FC<SectionProps> = (props) => {
  return (
		<Section style={{ background: props.background }} >
			{props.children}
		</Section>
	)
};
  
export default SectionComponent;
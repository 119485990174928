import * as React from "react"
import styled from 'styled-components';
import { Link } from 'gatsby';
import Hero from '../components/Hero';
import HeroImage from "../components/HeroImage";
// import Video from '../components/Video';
import Section from '../components/Section';
import Wrapper from '../components/Wrapper';
import LinkList from '../components/LinkList';
import Blockquote from '../components/Blockquote';
import background from '../assets/images/about/hero.jpeg';
// import bballVideo from '../assets/videos/pexels-cottonbro-5275206.mp4';

import enoch from '../assets/images/home/testimonial-enoch.jpeg';
import nate from '../assets/images/home/testimonial-nate.jpeg';
import enochAvatar from '../assets/images/home/thumb-enoch.jpeg';
import nateAvatar from '../assets/images/home/thumb-nate.jpeg';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
	padding: 2rem 0;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ColHalf = styled.div`
  width: 50%;

	img {
		width: 100%;
	}

	&.image {
		@media (max-width: 900px) {
			order: 0;
		}
	}

	&.quote {
		@media (max-width: 900px) {
			order: 1;
		}
	}

  @media (max-width: 900px) {
    width: 100%;
  }
`;

const IndexPage = () => {
  return (
    <main>
			<Hero>
				<HeroImage src={background} />
        <h1>
					<span>We</span>
					<span>are</span>
					<span>an</span>
					<span>athlete</span>
					<span>representation</span>
					<span>and</span>
					<br className="hidden-sm"></br>
					<span>management</span>
					<span>business</span>
					<span>founded</span>
					<span>in</span>
					<span>the</span>
					<br className="hidden-sm"></br>
					<span>Pacific</span>
					<span>Northwest</span>
				</h1>
      </Hero>
      <Section>
        <Wrapper>
          <LinkList>
            <li><Link to="/about" className="about"><span>About PNW</span></Link></li>
            <li><Link to="/services" className="services">Services</Link></li>
            <li><Link to="/clients" className="talent">Talent</Link></li>
            <li><Link to="/contact" className="contact">Get in Touch</Link></li>
          </LinkList>
        </Wrapper>
			</Section>
			
			<Section>
				<Wrapper className="large">
					<Row>
						<ColHalf className="image">
							<img src={nate} />
						</ColHalf>

						<ColHalf className="quote">
							<Blockquote
								quote="Aman and PNW Sports Group have been everything I could ask for as I’ve transitioned into my post-NBA career. He has negotiated various contracts with guaranteed money for me. Over the years, Aman has become more than just an agent, he’s my family"
								name="Nate Robinson"
								title="Professional Client"
								img={nateAvatar}
							/>
						</ColHalf>
					</Row>

					<Row>
						<ColHalf className="quote">
							<Blockquote
								quote="PNW Sports Group has been a blessing, and has made my process simple in trying to reach my lifetime goal. As my NIL representative, Aman has organized different resources that have made the journey so much easier. I’m so glad we’re a team!"
								name="Enoch Boakye"
								title="Name, Image, and Likeness Client"
								img={enochAvatar}
							/>
						</ColHalf>

						<ColHalf className="image">
							<img src={enoch} />
						</ColHalf>
					</Row>
				</Wrapper>
			</Section>
    </main>
  )
}

export default IndexPage

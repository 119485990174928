import React, { FC } from 'react';
import styled from 'styled-components';

const Hero = styled.div`
	align-items: center;
  display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	max-width: 1000px;
	width: 100%;
	min-height: 100vh;
	position: relative;
	text-align: center;

	&.small {
		min-height: 60vh;
	}

	h1,
	h2 {
		font-size: 3rem;
		transform: scale(0.94);
  	animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);

		@media (max-width: 500px) {
      font-size: 2rem;
    }
	}

	@keyframes scale {
		100% {
			transform: scale(1);
		}
	}

	span {
		display: inline-block;
		margin: 0 .5rem;
		opacity: 0;
  	filter: blur(4px);
	}

	span:nth-child(1) {
		animation: fade-in 0.8s 0.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(2) {
		animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(3) {
		animation: fade-in 0.8s 0.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(4) {
		animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(5) {
		animation: fade-in 0.8s 0.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(6) {
		animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(7) {
		animation: fade-in 0.8s 0.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(8) {
		animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(9) {
		animation: fade-in 0.8s 0.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(10) {
		animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(11) {
		animation: fade-in 0.8s 1.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(12) {
		animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(13) {
		animation: fade-in 0.8s 1.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(14) {
		animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(15) {
		animation: fade-in 0.8s 1.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(16) {
		animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(17) {
		animation: fade-in 0.8s 1.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(18) {
		animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(19) {
		animation: fade-in 0.8s 1.9s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(20) {
		animation: fade-in 0.8s 2.0s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(21) {
		animation: fade-in 0.8s 2.1s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(22) {
		animation: fade-in 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(23) {
		animation: fade-in 0.8s 2.3s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(24) {
		animation: fade-in 0.8s 2.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(25) {
		animation: fade-in 0.8s 2.5s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(26) {
		animation: fade-in 0.8s 2.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(27) {
		animation: fade-in 0.8s 2.7s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	span:nth-child(28) {
		animation: fade-in 0.8s 2.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
	}

	@keyframes fade-in {
		100% {
			opacity: 1;
			filter: blur(0);
		}
	}

	@media (max-width: 1366px) {
		min-height: 90vh;

		h1 {
			margin: 0 1rem;
		}
	}
`;

interface HeroProps {
	children?: any;
	small?: boolean;
}

const HeroComponent: FC<HeroProps> = (props) => {
	return (
		<Hero className={props.small ? 'small' : ''}>
			{ props.children }
		</Hero>
	)
}

export default HeroComponent;
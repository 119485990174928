import React, { FC } from 'react';
import styled from 'styled-components';

const Blockquote = styled.blockquote`
  padding: 0;
  position: relative;

  .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .avatar-wrapper {
    margin-right: 1rem;
  }

  .avatar {
    width: 50px;
    height: 50px;
    border-radius: 50px;
  }

  .quote {
    margin-bottom: 2.5rem;
  }

  .icon {
    font-family: 'Averia Serif Libre';
    font-size: 5rem;
    line-height: 0.75;
    margin: 0;

    @media (max-width: 650px) {
      padding: -2rem;
    }
  }

  .name {
    font-size: 1.75rem;
    font-weight: 700;
    padding: 0;
    margin: 0;
  }

  .title {
    line-height: 1;
    padding: 0;
    margin: 0;
  }

  @media (min-width: 650px) {
    padding: 2rem;
  }
`;

interface BlockquoteProps {
  quote: string;
  name: string;
  title: string;
  img: any;
}

const BlockquoteComponent: FC<BlockquoteProps> = (props) => {
  return (
    <Blockquote>
      <p className="icon">&ldquo;</p>
      <p className="quote">{props.quote}</p>
      <div className="flex">
        <div className="avatar-wrapper">
          <img className="avatar" src={props.img} />
        </div>
        <div>
          <p className="name">{props.name}</p>
          <p className="title">{props.title}</p>
        </div>
      </div>
    </Blockquote>
  );
};

export default BlockquoteComponent;

import styled from 'styled-components';
import about from '../../assets/images/home/home-about.jpeg';
import services from '../../assets/images/home/home-services.jpeg';
import talent from '../../assets/images/home/home-talent.jpeg';
import contact from '../../assets/images/home/home-contact.jpeg';

const LinkList = styled.ul`
  text-decoration: none;
  padding: 0;
  margin: 4rem 0;
  list-style-type: none;

  li {
    padding: 0;
    margin: 0;
    text-decoration: inherit;
    text-align: center;
  }

  a {
    background: #eee;
    background-size: cover;
		background-repeat: no-repeat;  
    padding: 7rem;
    margin: 2rem auto;
    display: block;
    font-size: 2.5rem;
    text-decoration: none;
    font-weight: bold;
    color: #fff;
    filter: grayscale(1);
    transition: background ease-in-out .3s;
		text-shadow: 2px 2px 5px rgba(0,0,0,.5);

    &.about {
      background-image: url(${about});
      background-position: center;
    }

    &.services {
      background-image: url(${services});
      background-position: center;
    }

    &.talent {
      background-image: url(${talent});
      background-position: bottom;
    }

    &.contact {
      background-image: url(${contact});
      background-position: center;
    }

    &:hover {
      color: #fff;
      filter: grayscale(0) brightness(1);
      background-size: 120%;
      background-color: #c39954;
      background-blend-mode: multiply;
    }
  }
`;

export default LinkList;